import "./EmailVerificationSuccess.scss";

function EmailVerificationSuccess() {
  return (
    <>
      <div>
        <div>
          <h1>
            ¡Verificación éxitosa!
          </h1>
          <p>
            Su correo electrónico se verifico éxitosamente, ya puede iniciar sesión en BHappy.
          </p>
        </div>
        <div>
          <footer>
            BHappy 2024
          </footer>
        </div>
      </div>
    </>
  );
}

export default EmailVerificationSuccess;
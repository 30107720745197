import "./PasswordChangeSuccess.scss";

function PasswordChangeSuccess() {
  return (
    <>
      <h1>Tu contraseña ha sido restablecida con éxito</h1>
      <p>Puedes cerrar esta ventana e iniciar sesión como de costumbre con tu nueva contraseña.</p>
    </>
  );
}

export default PasswordChangeSuccess;
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FirebaseAppProvider } from 'reactfire';
import useLocationHash from './hooks/useLocationHash';
import firebaseConfig from './firebaseConfig';

const FirebaseAppFromApiKey = function () {
  const hash = useLocationHash();
  const apiKey = hash["apiKey"] ?? "";
  const mode = hash["mode"] ?? "";

  useEffect(() => {
    // Permitimos el restablecimiento de contraseña y la verificación de correo electrónico
    if (mode !== "resetPassword" && mode !== "verifyEmail") {
      const redirectParams = Object.keys(hash).reduce(
        (s, key) => `${s ? `${s}&` : "?"}${key}=${hash[key]}`,
        ""
      );
      const currentUrl = window.location.href;
      const redirectUrl = `https://soporte.bhappyvenezuela.com/${redirectParams}`;

      // Evitamos el bucle de recarga
      if (currentUrl !== redirectUrl) {
        window.location.href = redirectUrl;
      }
    }
  }, [mode, hash]);

  useEffect(() => {
    if (!firebaseConfig.apiKey) {
      console.log("**************** WARNING ****************");
      console.log("You have not set up an API key. We will use the API key passed in via params. This will work, but means");
      console.log("your hosted app can be used by other Firebase projects.");
      console.log("If that's your intention, then this warning can be ignored.");
      console.log("Otherwise please update the firebaseConfig.ts file.");
      console.log("*******************************************");
    }
    if (!firebaseConfig.projectId) {
      console.log("**************** WARNING ****************");
      console.log("You have not set up a project ID. Without this we won't reliably be able to redirect to the default");
      console.log("/auth/action app in case this app is required to perform something other than password reset.");
      console.log("Please update the firebaseConfig.ts file.");
      console.log("*******************************************");
    }
  }, []);

  const usedFirebaseConfig = firebaseConfig.apiKey ? firebaseConfig : { ...firebaseConfig, apiKey };

  return (
    <FirebaseAppProvider firebaseConfig={usedFirebaseConfig}>
      <App />
    </FirebaseAppProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <FirebaseAppFromApiKey />
  </React.StrictMode>
);

reportWebVitals();
import { useEffect, useState } from "react";

function useLocationHash() {
  const getParams = function () {
    const searchParams = new URLSearchParams(window.location.search);
    const hashParams = new URLSearchParams(window.location.hash.substring(1));

    const params: { [key: string]: string } = {};

    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }

    for (const [key, value] of hashParams.entries()) {
      params[key] = value;
    }

    return params;
  };

  const [params, setParams] = useState(getParams());

  useEffect(() => {
    const handleChange = () => setParams(getParams());
    window.addEventListener("popstate", handleChange);
    window.addEventListener("hashchange", handleChange);
    return () => {
      window.removeEventListener("popstate", handleChange);
      window.removeEventListener("hashchange", handleChange);
    };
  }, []);

  return params;
}

export default useLocationHash;
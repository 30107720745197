import { FirebaseError } from 'firebase/app';
import { applyActionCode, checkActionCode, confirmPasswordReset, getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { AuthProvider, useFirebaseApp } from 'reactfire';
import './App.scss';
import useLocationHash from './hooks/useLocationHash';
import VerifyingActionCode from './modules/verifyingActionCode/VerifyingActionCode';
import FatalError from './modules/fatalError/FatalError';
import PasswordChange from './modules/passwordChange/PasswordChange';
import { SubmissionState } from './types';
import PasswordChangeSuccess from './modules/passwordChangeSuccess/PasswordChangeSuccess';
import EmailVerificationSuccess from './modules/emailVerificationSuccess.tsx/EmailVerificationSuccess';
import logo from './assets/logo.png';



function App() {
  // ¿Estamos verificando el código de acción?
  const [actionCodeVerificationInProgress, setActionCodeVerificationInProgress] = useState(true);

  const actionCode = useLocationHash()["oobCode"];
  const mode = useLocationHash()["mode"];

  const auth = getAuth(useFirebaseApp());

  // Cualquier error fatal que ocurra en cualquier parte del proceso
  const [fatalError, setFatalError] = useState<string | null>(null);

  // Cualquier error retriable que ocurra al intentar enviar la nueva contraseña
  const [submitError, setSubmitError] = useState<string | null>(null);

  // ¿Estamos enviando la nueva contraseña o se ha enviado correctamente?
  const [submissionState, setSubmissionState] = useState(SubmissionState.NOT_SUBMITTING);

  async function submitNewPassword(password: string) {
    setSubmissionState(SubmissionState.SUBMITTING);
    setSubmitError(null);
    try {
      await confirmPasswordReset(auth, actionCode, password);
      setSubmissionState(SubmissionState.SUCCESSFULLY_SUBMITTED)
    }
    catch (e) {
      setSubmissionState(SubmissionState.NOT_SUBMITTING);
      const errorCode = (e as FirebaseError).code;
      switch (errorCode) {
        // ... manejo de errores existente ...
        default:
          setSubmitError("No se pudo restablecer tu contraseña. Por favor, verifica tu conexión a internet y vuelve a intentarlo.");
          break;
      }
    }
  }

  // Tan pronto como tengamos nuestro código de acción (OOB), lo verificamos para asegurarnos de que es válido
  useEffect(() => {
    setActionCodeVerificationInProgress(true);
  
    async function verifyEmail() {
      try {
        await applyActionCode(auth, actionCode);
      }
      catch (e) {
        const errorCode = (e as FirebaseError).code;
        switch (errorCode) {
          case "auth/expired-action-code":
            setFatalError("El enlace en tu correo electrónico ha expirado. Por favor, intenta verificar tu correo nuevamente.");
            break;
          case "auth/invalid-action-code":
            setFatalError("El enlace en tu correo electrónico no es válido o ya ha sido utilizado. Por favor, intenta verificar tu correo nuevamente.");
            break;
          case "auth/user-disabled":
            setFatalError("Tu cuenta ha sido deshabilitada.");
            break;
          case "auth/user-not-found":
            setFatalError("No se pudo encontrar tu cuenta.");
            break;
          default:
            setFatalError("No se pudo verificar tu correo electrónico. Por favor, verifica tu conexión a internet y vuelve a intentarlo.");
            break;
        }
        console.error("Error verificando código de acción", e);
      }
    }
  
    async function verifyActionCode() {
      setActionCodeVerificationInProgress(true);
      setFatalError(null);
  
      try {
        if (mode === "resetPassword") {
          await checkActionCode(auth, actionCode);
        } else if (mode === "verifyEmail") {
          await verifyEmail();
        }
      }
      catch (e) {
        // ... manejo de errores existente ...
      }
      finally {
        setActionCodeVerificationInProgress(false);
      }
    }
  
    verifyActionCode();
  }, [auth, actionCode, mode]);
  
  

  return (
    <AuthProvider sdk={auth}>
      <div className='head'>
          <div className='logo'><img src={ logo } alt="My Logo" /></div>
      </div>
      <div className="App">
        {fatalError &&
          <FatalError errorText={fatalError} />
        }
        {!fatalError && actionCodeVerificationInProgress &&
          <VerifyingActionCode />
        }
        {!fatalError && !actionCodeVerificationInProgress && mode === "resetPassword" && submissionState !== SubmissionState.SUCCESSFULLY_SUBMITTED &&
          <PasswordChange
            submissionState={submissionState}
            submitError={submitError}
            submitNewPassword={(password) => submitNewPassword(password)}
          />
        }
        {!fatalError && !actionCodeVerificationInProgress && mode === "resetPassword" && submissionState === SubmissionState.SUCCESSFULLY_SUBMITTED &&
          <PasswordChangeSuccess />
        }
        {!fatalError && !actionCodeVerificationInProgress && mode === "verifyEmail" &&
          <EmailVerificationSuccess />
        }
      </div>
    </AuthProvider>
  );
}

export default App;
import { useState } from "react";
import { SubmissionState } from "../../types";
import "./PasswordChange.scss";

const MIN_CHARS = 8;
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).+$/;

export interface PasswordChangeParams {
  submissionState: SubmissionState;
  submitNewPassword: (password: string) => void;
  submitError: string | null;
}

function PasswordChange(params: PasswordChangeParams) {
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  function setNewPassword(password: string) {
    setNewPassword1(password);
    if (password.length < MIN_CHARS) {
      setPasswordError(`La contraseña debe tener al menos ${MIN_CHARS} caracteres`);
    } else if (!PASSWORD_REGEX.test(password)) {
      setPasswordError(
        "La contraseña debe contener al menos una mayúscula, una minúscula, un número y un carácter especial"
      );
    } else {
      setPasswordError(null);
    }
  }

  // Función para verificar si las contraseñas están vacías o solo contienen espacios
  const isPasswordEmpty = (password: string) => password.trim().length === 0;

  return (
    <div className="PasswordChange">
      <h1>Restablece tu contraseña</h1>
      <p>
        Gracias por confirmar tu dirección de correo electrónico. Puedes restablecer tu contraseña a continuación.
      </p>

      {/* Nueva contraseña */}
      <label htmlFor="newPassword1">Nueva contraseña</label>
      <div className="password-input-container">
        <input
          type={showPassword1 ? "text" : "password"}
          id="newPassword1"
          value={newPassword1}
          onChange={(event) => setNewPassword(event.target.value)}
        />
        <span
          className="toggle-password"
          onClick={() => setShowPassword1(!showPassword1)}
        >
          {showPassword1 ? (
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M1 12S5 4 12 4s11 8 11 8-4 8-11 8S1 12z" />
              <circle cx="12" cy="12" r="3" />
            </svg>
          ) : (
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M17.94 17.94A10.97 10.97 0 0112 19c-7 0-11-7-11-7a14.92 14.92 0 015.07-5.92" />
              <path d="M1 1l22 22" />
            </svg>
          )}
        </span>
      </div>
      {passwordError && <p className="error">{passwordError}</p>}

      {/* Confirmar contraseña */}
      <label htmlFor="newPassword2">Confirmar contraseña</label>
      <div className="password-input-container">
        <input
          type={showPassword2 ? "text" : "password"}
          id="newPassword2"
          value={newPassword2}
          onChange={(event) => setNewPassword2(event.target.value)}
        />
        <span
          className="toggle-password"
          onClick={() => setShowPassword2(!showPassword2)}
        >
          {showPassword2 ? (
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M1 12S5 4 12 4s11 8 11 8-4 8-11 8S1 12z" />
              <circle cx="12" cy="12" r="3" />
            </svg>
          ) : (
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M17.94 17.94A10.97 10.97 0 0112 19c-7 0-11-7-11-7a14.92 14.92 0 015.07-5.92" />
              <path d="M1 1l22 22" />
            </svg>
          )}
        </span>
      </div>
      {newPassword2.length > 0 && newPassword1 !== newPassword2 && (
        <p className="error">Las contraseñas no coinciden</p>
      )}

      {/* Botón de envío */}
      <button
        disabled={
          params.submissionState !== SubmissionState.NOT_SUBMITTING ||
          !!passwordError ||
          newPassword1 !== newPassword2 ||
          isPasswordEmpty(newPassword1) ||
          isPasswordEmpty(newPassword2)
        }
        onClick={() => params.submitNewPassword(newPassword1)}
      >
        {params.submissionState === SubmissionState.SUBMITTING
          ? "ENVIANDO..."
          : "ENVIAR"}
      </button>
      {params.submitError && <p className="error">{params.submitError}</p>}
    </div>
  );
}

export default PasswordChange;